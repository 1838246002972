@import '../styles/colors';

#EmailInput {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
}
.CopyWidget {
  background: transparent;
  outline: none;
  border: none;
  > input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    z-index: -1;
  }
  > svg {
    fill: $lightAccent;
  }
}
