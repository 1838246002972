@import '../styles/colors.scss';
@import '../styles/mediaQueries.scss';
@import '../styles/shadows.scss';

header.MobileHeader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 0 24px;
  transition: all 0.3s ease-in-out;

  &.__isFixed {
    background: $lightShade;
    z-index: 15;
    @include cardShadow;
    > ._currentSection {
      opacity: 1;
    }
    > ._open {
      > button._lines {
        > span {
          border: 2px solid $darkShade;
        }
      }
    }
  }
  > ._profile {
    display: block;
    z-index: 4;
    padding: 8px 0;

    > img {
      display: block;
      height: 62px;
      @include cardShadow;
      transition: all 0.3s ease-in-out;
      border-radius: 200px;
    }
  }

  > ._currentSection {
    text-transform: uppercase;
    font-size: 24px;
    color: $darkShade;
    font-weight: 600;
    opacity: 0;
    @include transition;
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    > h2 {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 50px;
      @include transition;
      &._home {
      }
      &.__isAbove {
        top: -200%;
        opacity: 0;
      }
      &.__isCurrent {
        top: 0;
        opacity: 1;
      }
      &.__isBelow {
        top: 100%;
        opacity: 0;
      }
    }
  }

  &.__isOpen {
    z-index: 30;
    > ._profile {
      > img {
        @include cardShadow;
      }
    }
  }

  > ._open {
    cursor: pointer;
    width: 38px;
    user-select: none;
    min-width: 62px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button._lines {
      background: transparent;
      outline: none;
      z-index: 4;
      height: 32px;
      width: 32px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      > span {
        max-width: 38px;
        border: 2px solid $lightShade;
        transition: all 0.3s ease-in-out;
        width: 100%;
      }
    }

    &.__isOpen {
      > button._lines {
        > span {
          border: 2px solid $darkShade;
          &:first-of-type {
            transform: translateY(14px) rotate(45deg);
          }
          &:nth-of-type(2) {
            transform: translateY(4px) rotate(45deg);
            opacity: 0;
          }
          &:nth-of-type(3) {
            transform: translateY(-4px) rotate(-45deg);
            opacity: 0;
          }
          &:last-of-type {
            transform: translateY(-14px) rotate(-45deg);
          }
        }
      }
    }
  }
  > nav {
    z-index: 3;
    position: absolute;
    top: -100vh;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: $lightShade;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 24px;
    a {
      text-align: center;
      padding: 18px;
      font-size: 24px;
      font-weight: 600;
      text-decoration: none;
      color: $darkShade;
      margin: 12px 18px;
      border-radius: 2px;
      background: $lightAccent;
      text-transform: uppercase;

      &:last-of-type {
        background: $darkAccent;
        color: $lightShade;
      }
    }
  }
  &.__isOpen {
    > nav {
      top: 0;
    }
  }
}
