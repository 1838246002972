@import '../styles/colors';
@import '../styles/shadows';

.SocialLogo {
  &._lightShade {
    > svg {
      > path {
        fill: $lightShade;
      }
      > circle {
        stroke: $lightShade;
      }
    }
  }
  &._brandColor {
    > svg {
      > path {
        fill: $brandColor;
      }
      > circle {
        stroke: $brandColor;
      }
    }
  }
}
