@import '../styles/colors';
@import '../styles/shadows';

.Role {
  padding: 32px 0;
  display: flex;

  &:first-of-type {
    padding-top: 16px;
  }

  &:last-of-type {
    padding-bottom: 128px;
  }

  > ._timeline {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    > ._years {
      position: sticky;
      top: 224px;
      max-width: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:before {
        content: '';
        display: block;
        position: absolute;
        background: $lightShade;
        z-index: -1;
        top: -32px;
        bottom: -32px;
        left: 0;
        right: 0;
      }


      > p {
        font-size: 32px;
        font-weight: 500;
        color: $darkAccent;
        background: $lightShade;
        text-align: center;
      }
    }
    > span {
      flex-grow: 1;
      border: 1px solid $darkAccent;
    }
  }

  > ._details {
    margin-left: 48px;
    > ._logo {
      display: flex;
      align-items: center;
      z-index: 6;
      background: $lightShade;
      position: sticky;
      top: 138px;
      margin-bottom: 32px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -22px;
        left: -14px;
        right: 0;
        bottom: 4px;
        width: 100vw;
        background: $lightShade;
        z-index: -1;
        box-shadow: 0px 0px 16px 8px $lightShade;
      }

      > .CompanyLogo {
        height: 42px;
        > svg {
          height: 42px;
          width: auto;
          path {
            fill: $darkShade;
          }
        }
      }
      &.consulting {
        display: none;
      }
      &.wednesday {
        > .CompanyLogo {
          transform: translate(-6px, 6px);
        }
      }
      &.hone {
        > .CompanyLogo {
          transform: translateY(3px);
        }
      }
      &.minted {
        > .CompanyLogo {
          transform: translateY(-2px);
        }
      }
      &.huge {
        > .CompanyLogo {
          transform: translateY(2px);
        }
      }

    }

    > h3 {
      font-weight: 600;
      font-size: 28px;
      line-height: 30px;
      color: $darkShade;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-bottom: 12px;
    }
    > h4 {
      font-size: 24px;
      line-height: 24px;
      color: $darkShade;
      font-weight: 500;
      margin-bottom: 24px;
      letter-spacing: 1px;
    }
    > p {
      @include cardShadow;
      white-space: pre-wrap;
      padding: 24px 0;
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      background: $lightAccent;
      padding: 32px;
      border-radius: 6px;
      color: $darkShade;
    }
  }
}
