@import '../styles/transitions';
@import '../styles/timing';

.Revealer {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  &.__isVisible {
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }
}
