@import '../styles/colors';
@import '../styles/shadows';

.MobileRole {
  padding: 24px;
  &#Role-wednesday {
    >.CompanyLogo {
      margin-bottom: 12px;
    }
  }
  > .CompanyLogo {
    display: block;
    text-align: center;
    margin-bottom: 18px;
    > svg {
      height: 38px;
      width: auto;
      path {
        fill: $darkShade;
      }
    }
  }
  > h3 {
    font-size: 22px;
    line-height: 26px;
    font-weight: 500;
    text-align: center;
    color: $darkShade;
    text-transform: uppercase;
  }
  > h4 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
    color: $brandColor;
    margin-bottom: 4px;
  }
  > h5 {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    text-align: center;
    color: $brandColor;
    margin-bottom: 24px;
  }
  > p {
    white-space: pre-wrap;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: $darkShade;
    background: $lightAccent;
    border-radius: 6px;
    @include cardShadow;
    padding: 18px;
  }
}
