@import '../styles/mediaQueries';

.StickyHeader {
  > ._linkAnchor {
    position: absolute;
    top: -106px;
    @include mobile {
      top: 32px;
    }
  }
}
