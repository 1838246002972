@import '../styles/colors';
@import '../styles/mediaQueries';
@import '../styles/shadows';

.Roles {
  position: relative;
  background: $lightShade;
  z-index: 1;
  @include mobile {
    padding: 48px 0;
  }

  &.__isFixed {
    > header {
      @include cardShadow;
      @include mobile {
        box-shadow: unset;
        position: static;
      }
      > h2 {
        transform: scale(.6) translateX(-600px);
        @include mobile {
          transform: unset;
        }
      }
    }
  }

  > header {
    z-index: 5;
    padding: 12px;
    padding-top: 48px;
    transition: all .3s ease-in-out;
    position: sticky;
    top: 84px;
    background: $lightShade;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile {
      top: 84px;
      padding: 0;
      width: 100%;
      background: transparent;
      z-index: 20;
      position: static;
      box-shadow: unset;
    }

    &.__isFixed {
      @include cardShadow;
      > h2 {
        transform: scale(.6) translateX(-600px);
        @include mobile {
          transform: unset;
        }
      }
    }

    > h2 {
      font-size: 48px;
      text-transform: uppercase;
      font-weight: 600;
      color: $darkAccent;
      padding: 6px;
      background: rgba(243, 243, 243, 0);
      transition: all .3s ease-in-out;
      letter-spacing: 3px;
      transform: scale(1) translateX(-194px);
      text-align: center;
      @include mobile {
        transform: unset;
      }
    }
  }

  > p {
    margin-top: 400px;
  }

  > ._body {
    position: relative;
    > ._roles {
      max-width: 800px;
      margin: 0 auto;
      z-index: -1;
    }
  }
}
