@import '../styles/colors';
@import '../styles/shadows';

.MobileProject {
  padding: 24px;
  > h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: $darkShade;
  }
  > h4 {
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: $darkShade;
    margin-bottom: 12px;
  }
  > ._projectDetails {
    margin-bottom: 8px;
    > .CompanyLogo {
      display: block;
      text-align: center;
      width: 100%;
      > svg {
        height: 24px;
        width: auto;
        > path {
          fill: $brandColor;
        }
      }
    }
    > h4 {
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      text-align: center;
      color: $brandColor;
    }
  }
  > h5 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    text-align: center;
    color: $darkShade;
    margin-bottom: 18px;
  }
  > p {
    white-space: pre-wrap;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: $darkShade;
    background: $lightShade;
    border-radius: 6px;
    @include cardShadow;
    padding: 18px;
  }
}
