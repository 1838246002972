@import '../styles/colors';
@import '../styles/mediaQueries';
@import '../styles/shadows';

.Project {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 24px 0;
  margin-bottom: 24px;

  > ._timeline {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    > ._years {
      position: sticky;
      top: 224px;
      max-width: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &:before {
        content: '';
        display: block;
        position: absolute;
        background: $lightAccent;
        z-index: -1;
        top: -32px;
        bottom: -32px;
        left: 0;
        right: 0;
      }

      > p {
        font-size: 32px;
        font-weight: 500;
        color: $darkAccent;
        text-align: center;
      }
    }

    > span {
      flex-grow: 1;
      border: 1px solid $darkAccent;
    }
  }

  > ._projectDetails {
    flex-grow: 1;
    margin-left: 48px;
    > h2 {
      font-weight: 700;
      line-height: 42px;
      position: sticky;
      top: 142px;
      z-index: 10;
      color: $darkShade;
      text-transform: uppercase;
      font-size: 32px;
      letter-spacing: 2px;
      background: $lightAccent;
      margin-bottom: 32px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -24px;
        left: -8px;
        right: 0;
        bottom: 4px;
        width: 100vw;
        z-index: -1;
        box-shadow: 0px 0px 16px 10px $lightAccent;
        background: $lightAccent;
      }
    }
    > ._details {
      > ._detail {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        > svg {
          min-width: 48px;
          path, polygon {
            fill: $darkShade;
          }
        }
        > .CompanyLogo {
            height: 22px;
            flex-grow: 1;
          > svg {
            height: 22px;
            width: auto;
            > path {
              fill: $darkShade;
              stroke: $darkShade;
            }
          }
        }
        > span {
          min-width: 140px;
          font-size: 16px; 
          line-height: 18px;
          font-weight: 500;
          margin-right: 12px;
          color: $darkShade;
        }
        > h4 {
          font-size: 16px;
          line-height: 18px;
          font-weight: 600;
          color: $darkShade;
          elex-grow: 1;
        }
        &._stack {
          > h4 {
            font-weight: 400;
          }
        }
      }
    }
    > p {
      white-space: pre-wrap;
      padding: 24px 0;
      font-size: 24px;
      line-height: 32px;
      font-weight: 300;
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      padding: 32px;
      border-radius: 6px;
      @include cardShadow;
      background: $lightShade;
      color: $darkShade;
    }
  }
}
