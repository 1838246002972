@import "../styles/colors";
@import "../styles/shadows";
@import "../styles/animations";
@import "../styles/mediaQueries";

.Home {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  max-width: 800px;
  margin: 0 auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  @include transition;
  &.__isVisible {
    opacity: 1;
    pointer-events: all;
  }

  ._topRow {
    flex-grow: 1;
    @include mobile {
      flex-grow: unset;
    }
  }
  > ._centerRow {
    display: flex;
    flex-direction: column;
    @include mobile {
      flex-grow: 1;
      align-items: center;
      justify-content: flex-end;
    }
    > a {
      text-decoration: none;
      > h1 {
        display: block;
        text-transform: uppercase;
        font-size: 78px;
        margin-bottom: 48px;
        letter-spacing: 2px;
        color: $lightShade;
        text-shadow: 2px 2px 2px #000;
        font-weight: 500;
        opacity: 0;
        @include textShadow;
        animation: enter 2s $softBezier forwards 1s;
        text-decoration: none;
        @include mobile {
          font-size: 48px;
          margin: 0;
        }
      }
    }
    > h2,
    h3 {
      font-size: 24px;
      line-height: 48px;
      font-weight: 400;
      margin: 0 auto;
      color: $lightShade;
      opacity: 0;
      @include fadeIn($duration: 0.5s, $delay: 2s);
      @include mobile {
        display: none;
      }
      > a {
        font-weight: 600;
        text-decoration: none;
        color: $brandColor;
        @include textShadow;
        margin: 0 6px;
      }
    }
  }
  > ._bottomRow {
    flex-grow: 1;
    display: flex;
    align-items: center;
    @include mobile {
      flex-direction: column;
      justify-content: space-evenly;
    }
    > a {
      color: $lightShade;
      font-size: 24px;
      line-height: 36px;
      font-weight: 400;
      letter-spacing: 2px;
      text-decoration: none;
      opacity: 0;
      min-width: 600px;
      @include fadeIn($duration: 2s, $delay: 3s);
      @include mobile {
        font-size: 18px;
        font-weight: 400;
        min-width: unset;
        @include fadeIn($duration: 2s, $delay: 2s);
      }
      > span {
        display: block;
        font-size: 48px;
        line-height: 64px;
        font-weight: 600;
        text-transform: uppercase;
        @include textShadow;
        color: $lightAccent;
        @include mobile {
          font-size: 24px;
          line-height: 24px;
        }
      }
    }
    > ._opportunities {
    }
    > ._mobileContact {
      display: none;
      @include mobile {
        display: block;
        width: 100%;
        background: $darkAccent;
        @include cardShadow;
        font-size: 24px;
        line-height: 26px;
        padding: 14px;
        font-weight: 500;
        text-transform: uppercase;
        border-radius: 3px;
        margin-bottom: 48px;
      }
    }
  }
}
