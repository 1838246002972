@import '../styles/colors.scss';
@import '../styles/mediaQueries.scss';
@import '../styles/shadows.scss';
@import '../styles/animations.scss';
@import '../styles/transitions.scss';


$dotSize: 72px;

.Navigation {
  position: fixed;
  width: $dotSize;
  bottom: 32px;
  right: 32px;
  z-index: 100;
  > a {
    @include cardShadow;
    @include transition;
    opacity: 1;
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    width: $dotSize;
    height: $dotSize;
    border-radius: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $darkAccent;
    transform: translateY(0);
    > svg {
      height: 42px;
      width: auto;
    }
  }
  > a._prevSection {
    opacity: 0;
    &.__isVisible {
      transform: translateY(-92px);
      opacity: 1;
    }
    &.__isSolo {
      transform: translateY(0);
      opacity: 1;
    }
    > svg {
      transform: rotate(180deg);
    }
  }
  > a._nextSection {
    &.__isHidden {
      opacity: 0;
      pointer-events: none;
    }
  }
}
