.Background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  > ._headerGradient {
    position: fixed;
    height: 150px;
    width: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, 0));
  }
}
