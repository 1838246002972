@import '../styles/colors.scss';
@import '../styles/shadows.scss';
@import '../styles/transitions.scss';

.Header {
  position: fixed;
  width: 100%;
  margin: 0 auto;
  z-index: 15;
  top: 0;
  left: 0;
  right: 0;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @include transition;
  background: none;
  border-bottom: 1px solid transparent;
  &.__isFixed {
    background: $lightShade;
    border-color: $darkShade;
    > .boundary {
      > .profile {
        > a {
          > img {
            @include buttonShadow;
          }
        }
      }
      > nav {
        > a {
          color: $darkShade;
        }
      }
      > ._contact {
        > a {
          @include buttonShadow;
        }
      }
    }
  }

  > .boundary {
    flex-grow: 1;
    max-width: 800px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .profile {
      flex-grow: 1;
      display: flex;
      > a {
        text-align: center;
        > img {
          display: block;
          max-height: 80px;
          border-radius: 200px;
        }
      }
    }

    > nav {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      > a {
        @include transition;
        color: $lightShade;
        text-decoration: none;
        font-weight: 600;
        padding: 12px 0;
        margin: 0 18px;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 1px;
        text-transform: uppercase;
        border-bottom: 2px solid rgba(0, 0, 0, 0);

        &:hover {
          color: $brandColor;
        }
      }
    }

    > ._contact {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      a {
        color: $lightShade;
        text-decoration: none;
        background: $darkAccent;
        padding: 12px 18px;
        font-weight: 500;
        text-transform: uppercase;
        border-radius: 3px;
        font-size: 20px;
      }
    }
  }
}
