@import '../styles/colors';
@import '../styles/mediaQueries';
@import '../styles/shadows';
@import '../styles/transitions';
@import '../styles/animations';

.CopyEmail {
  position: relative;
  > input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  > ._pseudoInput {
    color: $lightAccent;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 24px 72px;
    font-size: 24px;
    text-align: center;
    margin: 0 auto;
    border-radius: 4px;
    outline: none;
    border: none;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    opacity: 0;
    @include fadeIn;
    @include transition;
    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
  > ._hide {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    font-size: 18px;
    outline: none;
    border: none;
    padding: 4px;
    background: transparent;
    color: $lightAccent;
    cursor: pointer;
    @include transition;
    padding: 12px;
    > svg {
      fill: $lightAccent;
    }
  }
  > .CopyWidget {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    cursor: pointer;
    > svg {
      position: absolute;
      top: 8px;
      left: 8px;
      fill: $lightAccent;
    }
  }

  p {
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    text-align: center;
    color: $lightAccent;
    opacity: 0;
    @include transition;
    pointer-events: none;

    &._isVisible {
      opacity: 1;
    }
  }
}
