@import '../styles/colors';
@import '../styles/mediaQueries';
@import '../styles/shadows';
@import '../styles/timing';

@keyframes moveUp {
  0% {
    transform: translate(0, 0);
  }
  33% {
    transform: translate(-50px, -150px);
  }
  66% {
    transform: translate(50px, 50px);
  }
  100% {
    transform: translate(-25px, 100px);
  }
}

@keyframes sway {
  0% {
    transform: translate(0, 0);
  }
  33% {
    transform: translate(8px, -6px);
  }
  66% {
    transform: translate(2px, -2px);
  }
  100% {
    transform: translate(-4px, 4px);
  }
}

.About {
  position: relative;
  padding-top: 600px;
  z-index: 0;

  #home {
    position: absolute;
    top: 0;
  }

  #about {
    position: absolute;
    top: 1030px;
  }

  main {
    background: transparent;
    display: flex;
    flex-direction: column;
    min-height: 6000px;
    position: relative;
    padding-bottom: 72px;
    border-radius: 8px;
    overflow: visible;
    @include mobile {
      min-height: 2575px;
      padding: 0;
      max-width: 350px;
      margin: 0 auto;
      overflow: visible;
      pointer-events: none;
    }
  }
}
