@import '../styles/colors';
@import '../styles/shadows';
@import '../styles/mediaQueries';

@mixin cardStyle() {
  padding: 12px 24px;
  background: $lightAccent;
  position: absolute;
  border-radius: 3px;
  @include cardShadow;
  @include mobile {
    padding: 8px 12px;
  }
}
@mixin cardP() {
  color: $darkShade;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  font-weight: 500;
  @include mobile {
    font-size: 12px;
    line-height: 18px;
  }
}

@mixin cardEm() {
  color: $darkAccent;
  font-size: 24px;
  line-height: 32px;
  margin: 0 4px;
  @include mobile {
    font-size: 16px;
    line-height: 20px;
  }
}

.AboutStory {
  width: 800px;
  margin: 0 auto;
  position: relative;
  top: 0;
  left: 0;
  @include mobile {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  > .Revealer {
    &._card {
      @include cardStyle();
      > p {
        @include cardP();
        > em {
          @include cardEm();
        }
      }

      &._card1 {
        top: 1140px;
        left: 300px;
        transform: rotate(-6deg);
        z-index: 10;
        @include mobile {
          z-index: unset;
          transform: rotate(-10deg);
          top: 470px;
          left: 108px;
        }
      }
      &._card2 {
        min-width: 386px;
        top: 1350px;
        left: 309px;
        z-index: 10;
        transform: rotate(4deg);
        @include mobile {
          transform: rotate(-3deg);
          z-index: unset;
          min-width: unset;
          top: 535px;
          left: 70px;
        }
      }
      &._card3 {
        min-width: 576px;
        top: 1605px;
        left: 140px;
        transform: rotate(-2deg);
        @include mobile {
          min-width: unset;
          top: 628px;
          left: 15px;
        }
      }
      &._card4 {
        min-width: 553px;
        top: 1850px;
        left: 100px;
        transform: rotate(3deg);
        @include mobile {
          min-width: unset;
          transform: rotate(1deg);
          top: 750px;
          max-width: 330px;
          left: 10px;
        }
      }
      &._card5 {
        top: 2090px;
        left: 98px;
        transform: rotate(1deg);
        @include mobile {
          top: 860px;
          max-width: 350px;
          left: 20px;
        }
      }
      &._card6 {
        top: 2367px;
        left: 190px;
        transform: rotate(3deg);
        @include mobile {
          min-width: unset;
          top: 993px;
          left: 68px;
        }
      }
      &._card7 {
        top: 2630px;
        left: 35px;
        transform: rotate(-4deg);
        @include mobile {
          max-width: 350px;
          top: 1090px;
          transform: rotate(-3deg);
          left: 20px;
        }
      }
      &._card8 {
        top: 2900px;
        left: 153px;
        z-index: 10;
        max-width: 600px;
        transform: rotate(5deg);
        @include mobile {
          max-width: 350px;
          top: 1190px;
          transform: rotate(1deg);
          left: 0px;
        }
      }
      &._card9 {
        top: 3120px;
        left: 0px;
        width: 650px;
        transform: rotate(-4deg);
        position: relative;
        z-index: 10;
        @include mobile {
          top: 1280px;
          transform: rotate(-2deg);
          left: 25px;
          width: unset;
          max-width: 300px;
        }
        > p {
          z-index: 4;
        }
        > ._mask {
          position: absolute;
          top: 2980px;
          left: -30px;
          transform: rotate(-4deg);
          z-index: 10;
          height: 56px;
          width: 355px;
          overflow: hidden;
          @include transition;
          display: none;
          z-index: 20;
          > ._card {
            @include cardStyle();
            @include transition;
            z-index: 500;
            position: absolute;
            top: 0;
            left: 0;
            box-shadow: none;
            z-index: 50;
            width: 650px;

            > p {
              @include cardP();
              > em {
                @include cardEm();
              }
            }
          }
        }
      }
      &._card10 {
        top: 3330px;
        left: 182px;
        transform: rotate(2.5deg);
        z-index: 10;
        @include mobile {
          top: 1380px;
          transform: rotate(0deg);
          left: 0;
          width: unset;
          max-width: 300px;
        }
      }
      &._card11 {
        top: 3500px;
        left: 240px;
        max-width: 500px;
        transform: rotate(5deg);
        @include mobile {
          top: 1470px;
          transform: rotate(2deg);
          right: 20px;
          left: unset;
          max-width: 300px;
        }
      }
      &._card12 {
        top: 3750px;
        left: 52px;
        transform: rotate(-2deg);
        @include mobile {
          top: 1630px;
          transform: rotate(-2deg);
          left: 10px;
          width: unset;
          max-width: 300px;
        }
      }
      &._card13 {
        top: 4080px;
        right: 0;
        min-width: 500px;
        transform: rotate(3deg);
        z-index: 10;
        @include mobile {
          top: 1770px;
          transform: rotate(4deg);
          left: unset;
          right: 0;
          min-width: unset;
          max-width: 320px;
        }
      }
      &._card14 {
        top: 4320px;
        left: 80px;
        transform: rotate(-3deg);
        @include mobile {
          top: 1920px;
          transform: rotate(0deg);
          left: unset;
          left: 0px;
          width: unset;
          max-width: 300px;
        }
      }
      &._card15 {
        top: 4558px;
        left: 245px;
        max-width: 555px;
        transform: rotate(7deg);
        @include mobile {
          top: 2010px;
          transform: rotate(-2deg);
          left: unset;
          right: 0;
          max-width: 300px;
        }
      }
      &._card16 {
        top: 4835px;
        left: 125px;
        transform: rotate(-6deg);
        @include mobile {
          transform: rotate(2deg);
          top: 2110px;
          left: 50px;
          z-index: 10;
        }
      }
      &._card17 {
        top: 5270px;
        left: 258px;
        z-index: 10;
        @include mobile {
          top: 2208px;
          left: 80px;
        }
      }
      &._card18 {
        top: 5270px;
        left: 258px;
        z-index: 10;
        display: none;
        @include mobile {
          top: 2225px;
          transform: rotate(0deg);
          left: 80px;
        }
      }
    }
  }
}
