@import '../styles/colors';
@import '../styles/mediaQueries';

.NavString {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;

  @include mobile {
    > svg {
      width: 350px;
      height: auto;
    }
  }
  ._data {
    position: fixed;
    background: rgba(0, 0, 0, .75);
    color: #fff;
    bottom: 150px;
    left: 0;
    min-width: 40%;
    padding: 4px 20px;
    margin: 0 auto;
    text-align: left;
    border-radius: 4px;
    z-index: 300;
    display: block;
    @include mobile {
      bottom: 0;
    }
    > p {
      margin: 12px 0;
    }
    &.__isVisible {
      display: block;
    }
  }
  > svg {
    overflow: visible;
    width: 800px;
    height: auto;
    @include mobile {
      width: 337px;
    }
    > ._background {
      stroke-dashoffset: 0;
      stroke-dasharray: 10e6;
      stroke-width: 3;
      stroke: $lightAccent;
      stroke: transparent;
    }
    > .NavTail {
      stroke-width: 14;
      stroke: $lightShade;
      stroke-miterlimit: 10;
    }
    > .NavDot {
      stroke-dasharray: 0, 10e6;
      stroke-width: 45;
      stroke: $brandColor;
      stroke-miterlimit: 80;
    }
  }
}
