@import './timing';

@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  
  80% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes enter {
  0%{
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes _fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@mixin fadeIn($duration: 0.3s, $delay: 0s) {
  animation: _fadeIn $duration $delay $softBezier both;
}

@mixin fadeOut($duration: 3s, $delay: 0s) {
  animation: _fadeIn $duration $delay $softBezier;
}
