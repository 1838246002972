@import '../styles/colors';
@import '../styles/timing';
@import '../styles/mediaQueries';

@keyframes slideIn {
  from {
    opacity: 0;
    top: 100%;
  }
  to {
    opacity: 1;
    top: 0;
  }
}
.EmailWidget {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 1);
  display: none;
  @include mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  > p {
    color: $lightShade;
    font-size: 16px;
    line-height: 20px;
  }

  ._external {
    position: absolute;
    top: -8px;
    right: -8px;
    padding: 16px;
    svg {
      height: 18px;
      width: auto;
      path {
        fill: $lightAccent;
      }
    }
  }

  > .CopyWidget {
    position: absolute;
    bottom: -8px;
    right: -8px;
    padding: 16px;
  }

  > ._confirm {
    position: absolute;
    bottom: -18px;
    left: 0;
    right: 0;
    text-align: center;
    color: $lightAccent;
    opacity: 0;
    &.__isVisible {
      opacity: 1;
      font-size: 14px;
    }
  }
}
