@import '../styles/colors';
@import '../styles/mediaQueries';
@import '../styles/shadows';
@import '../styles/transitions';
@import '../styles/animations';

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    top: 100%;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

.Contact {
  background: transparent;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0 auto;
  z-index: 1;
  @include mobile {
    justify-content: space-between;
    padding-top: 48px;
  }
  #contact {
    top: 32px;
  }

  > header {
    font-size: 42px;
    text-transform: uppercase;
    width: 100%;
    display: none;
    @include mobile {
      display: block;
    }

    > h2 {
      color: $lightShade;
      text-align: center;
      top: 24px;
      display: none;
      font-weight: 500;
      @include transition;
      @include mobile {
        display: block;
      }
    }
  }
  > ._hero {
    text-decoration: none;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
    flex-grow: 1;
    @include mobile {
      display: none;
    }
    > h2 {
      color: $lightShade;
      font-size: 72px;
      text-transform: uppercase;
      @include textShadow;
    }
  }

  > ._center {
    display: -webkit-flex;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    flex-grow: 1;
    position: relative;
    @include mobile {
      justify-content: center;
    }
    > ._socialLinks {
      display: flex;
      justify-content: space-between;
      position: relative;
      @include mobile {
        flex-direction: column;
        padding: 24px;
        flex-grow: unset;
      }
      > a {
        position: relative;
        min-width: 180px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px;
        text-decoration: none;
        cursor: pointer;
        @include transition;
        @include mobile {
          margin-bottom: 32px;
        }
        &:last-of-type {
          @include mobile {
            margin-bottom: 0;
          }
        }
        &:hover {
          background: rgba(0, 0, 0, 0.7);
          > .LinkIcon {
            opacity: 1;
          }
        }
        &._emailLink {
          @include mobile {
            order: -1;
          }
          &.__isviisble {
            > .LinkIcon {
              display: block;
            }
          }
          > .LinkIcon {
            display: none;
            @include mobile {
              display: none;
            }
          }
        }
        > .SocialLogo {
          margin-bottom: 24px;
          @include mobile {
            margin-bottom: 12px;
          }
          > svg {
            height: 80px;
            width: auto;
            @include mobile {
              height: 50px;
            }
          }
        }

        > p {
          color: $lightShade;
          font-size: 24px;
          @include mobile {
            font-size: 18px;
          }
        }

        > .LinkIcon {
          padding: 8px;
          position: absolute;
          top: 0;
          right: 0;
          opacity: 0;
          @include transition;
          @include mobile {
            opacity: 1;
          }
          > svg {
            height: 18px;
            width: auto;
            > path {
              fill: $lightAccent;
            }
          }
        }
      }
      > ._emailContact {
        position: relative;
        order: -1;
        > ._sendEmail {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          flex-align: center;
          justify-content: center;
          z-index: 40;
        }
      }
    }
    > ._copyEmail {
      position: relative;
      @include mobile {
        display: none;
      }

      .CopyEmail {
        position: absolute;
        top: 24px;
        left: 0;
        right: 0;
      }
    }
  }

  > footer {
    color: $lightShade;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    > h2 {
      margin-bottom: 18px;
      font-size: 18px;
      @include mobile {
        font-size: 16px;
      }
    }
    > p {
      margin-bottom: 32px;
      color: $lightAccent;
      @include mobile {
        font-size: 12px;
      }

      > ._year {
        margin-left: 4px;
      }
    }
  }
}
